import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { getNavLink } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { smTxt } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { ReactNode } from 'react';

const TheArticle = styled(Article)`
    a {
        color: ${({ theme }) => theme.colors.green2};
        transition: color 0.3s ease-in-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.green1};
        }
    }
`;

const Date = styled.p`
    ${smTxt};
    color: #242f38b3;
    margin-bottom: 0;
`;

const Link = ({ children, to }: { children: string; to: string }) => {
    if (!to.startsWith('https') && !to.startsWith('#')) {
        return <GatsbyLink to={to === '/' ? '/' : getNavLink(to)}>{children}</GatsbyLink>;
    }
    return (
        <a href={to} target={to.startsWith('#') ? '_self' : '_blank'} rel="noreferrer">
            {children}
        </a>
    );
};

type BlogPostProps = {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                seoTitle: string;
                description: string;
                published?: string;
                cover?: {
                    childImageSharp: ImageDataLike;
                };
            };
            excerpt: string;
            body: string & ReactNode;
        };
    };
};

export const BlogPost = ({ data }: BlogPostProps) => {
    const {
        mdx: {
            frontmatter: { title, seoTitle, description, published, cover },
            excerpt,
            body,
        },
    } = data;

    return (
        <React.Fragment>
            <Seo title={seoTitle || title} description={description || excerpt} />
            <CommonHero heading={title}>
                {cover ? (
                    <GatsbyImage
                        image={getImage(cover.childImageSharp) as IGatsbyImageData}
                        alt={title}
                        loading="eager"
                    />
                ) : (
                    ''
                )}
            </CommonHero>
            <TheArticle>
                <Date>{published}</Date>

                <MDXProvider components={{ Link }}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </TheArticle>
        </React.Fragment>
    );
};
export default BlogPost;

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                seoTitle
                description
                published(formatString: "DD MMMM YYYY")
                cover {
                    childImageSharp {
                        gatsbyImageData(width: 700, height: 356)
                    }
                }
            }
            excerpt
            body
        }
    }
`;
